// import libraries
import React from "react";
import ReactDOM from "react-dom";

// import styling
import "./index.css";

//import components
import App from "./App";

ReactDOM.render(
  <App />,

  document.getElementById("root"),
);
